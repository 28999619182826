import React, { Fragment } from 'react';

import { List, ListItem, Typography, Box, Link } from '@material-ui/core';


const PrivacyPolicy = () => {
    return (
        <Fragment>
            <Typography color="textSecondary" variant="h5">Summary</Typography>
            <br></br>
            <Typography>This website does not collect any personal information (except IP addresses) or use cookies.
            All users who do not login will not have any personal information saved. Your IP is only recorded if you login.
            <br></br>
            <br></br>
            You are not tracked, marketed to, or subject to analytics. Laplace's Daemon respects your privacy.
            </Typography>
            <br></br>
            <Typography color="textSecondary" variant="h5">1. What data this site collects</Typography>
            <br></br>
            <Typography>This website will record your IP address if you login. This is done for security reasons.</Typography>
            <br></br>
            <Typography color="textSecondary" variant="h5">2. How can I be forgotten?</Typography>
            <br></br>
            <Typography>If you request for your account to be deleted all stored information will be removed.</Typography>
            <br></br>
            <Typography color="textSecondary" variant="h5">3. How long do you keep my data for?</Typography>
            <br></br>
            <Typography>Your data is stored for the lifetime of your account.</Typography>
            <br></br>
            <Typography color="textSecondary" variant="h5">4. What about <Box style={{ display: 'inline' }} fontStyle="italic">functional</Box> cookies?</Typography>
            <br></br>
            <Typography>This website does not use cookies.
                        Through the use of JavaScript and your browsers <Box style={{ display: 'inline' }} fontFamily="Monospace">localStorage</Box> your session can be persisted. See the <Link href="cookie-policy">cookie policy</Link> for more information.</Typography>
        </Fragment>
    );
};

export default PrivacyPolicy;