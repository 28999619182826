import React, { useState, useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    cells: {
        whiteSpace: 'nowrap',
    },
    hover: {
        '&:hover': {
            backgroundColor: '#515151'
        }
    },
    highlight: {
        backgroundColor: '#515151'
    },
    sticky: {
        position: 'sticky',
        left: '0px',
        backgroundColor: theme.palette.background.paper,
        borderBottom: '1px solid rgba(81, 81, 81, 1)',
        zIndex: 1,

    },
    error: {
        color: theme.palette.error.main
    },
    '@supports (-moz-appearance:none)': {
        sticky: {
            backgroundClip: 'padding-box'
        }
    }
}));

const resetRow = (i, theme, id, className) => {
    var element = document.getElementById(id + '-row-' + i);
    if (!element.classList.contains(className)) {
        document.getElementById(id + '-sticky-col-' + i).style = 'background-color: ' + theme.palette.background.paper;
        element.style = '';
    }
};

const highlightRow = (i, id, className) => {
    var element = document.getElementById(id + '-row-' + i);
    if (!element.classList.contains(className)) {
        document.getElementById(id + '-sticky-col-' + i).style = 'background-color: #515151';
        element.style = '';
    }
};

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

const RaceDetailsTable = props => {
    const classes = useStyles();
    const theme = useTheme();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState(props.meta.ordering);
    const [orderBy, setOrderBy] = useState(props.meta.orderBy);
    const [selectedId, setSelectedId] = useState('');
    const [selectedName, setSelectedName] = useState('');


    const runners = props.runners.slice();
    
    const runnersFirst = (() => {
        if(runners.length > 1) return runners[0].name;
        else return '';
    })();

    const [runnersLength, setRunnersLength] = useState(runners.length);
    const [runnersFirstEntry, setRunnersFirstEntry] = useState(
        (() => {
            if(runners.length > 1) return runners[0].name;
            else return '';
        })()
    );

    useEffect(() => {
        if (runnersLength !== runners.length){
            if(runnersFirstEntry !== runnersFirst) setPage(0);
        }
    }, [runnersLength, runners.length, runnersFirstEntry, runnersFirst]);

    useEffect(() => {
        if (runnersLength !== runners.length){
            setRunnersLength(runners.length);
        }
    }, [runnersLength, runners.length]);

    useEffect(() => {
        if (runnersFirstEntry !== runnersFirst){
            setRunnersFirstEntry(runnersFirst);
        }
    }, [runnersFirstEntry, runnersFirst]);

    const cols = props.columns;
    const Toolbar = props.toolbar;

    const setClass = (i, id, className, name, onClick, hid) => {
        const sid = id + '-row-' + i;
        const element = document.getElementById(sid);
        element.style = '';

        const idx = runners.findIndex( r => r.name === name );
        runners[idx]['selected'] = true;

        if (selectedName === name) {
            element.classList.remove(className);
            runners[idx]['selected'] = false;
            element.style = 'background-color: ' + theme.palette.background.paper;
            setSelectedName('');
            setSelectedId('');
            onClick(0);
            return;
        }

        const oldidx = runners.findIndex( r => r.name === selectedName );
        if(selectedName) {
            runners[oldidx]['selected'] = false;    
        }

        setSelectedName(name);
        onClick(hid);

        if (selectedId) {
            let el = document.getElementById(selectedId);
            if (el) el.classList.remove(className);

            if (oldidx > 1) {
                el = document.getElementById(id + '-sticky-col-' + oldidx)
                if (el) el.style = 'background-color: ' + theme.palette.background.paper;
            }
        }
        
        setSelectedId(sid)

        if (element.classList.contains(className)) {
            element.classList.remove(className);
        }
        else element.classList.add(className);

    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        stableSort(runners, getComparator(order, orderBy));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const conditionalFormatting = (row) => {
        if (props.meta.colors) {
            for (var i = 0; i < props.meta.colors.length; i++) {
                if (props.meta.colors[i].conditional(row)) return 'conditional' + i;
            }
        }
    }

    const conditionalClasses = makeStyles(theme => ({
        ...props.meta.classes
    }))();

    return (
        <Paper>
            {props.race ? <Toolbar race={props.race} /> : <Toolbar/>}
            <TableContainer >
                <Table className={[classes.table, classes.cells].join(' ')} size="small" aria-label="Runners in table with race stats">
                    <TableHead>
                        <TableRow>
                            {cols.map((col, i) => {
                                return (
                                    <TableCell key={props.id + "-" + col.id} className={i === 0 ? classes.sticky : null} align={col.numeric ? 'right' : 'left'}
                                        sortDirection={orderBy === col.id ? order : false}>
                                        {
                                            col.sortable ? <TableSortLabel
                                                active={orderBy === col.id}
                                                direction={orderBy === col.id ? order : 'asc'}
                                                onClick={() => handleRequestSort(null, col.id)}
                                            >{col.label}
                                            </TableSortLabel>
                                                : col.label
                                        }
                                    </TableCell>
                                );
                            }
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {runners.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={cols.length}><i>No form found</i></TableCell>
                            </TableRow>
                        }
                        {stableSort(runners, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                                return (
                                    <TableRow 
                                        key={props.id + "-" + row.name + "-" + i} 
                                        //onMouseEnter={() => highlightRow(i, props.id, classes.highlight)}
                                        onMouseOver={() => highlightRow(i, props.id, classes.highlight)} 
                                        onMouseLeave={() => resetRow(i, theme, props.id, classes.highlight)}
                                        onClick={props.clickable ? () => setClass(i, props.id, classes.highlight, row.name, props.onClick, row.hid) : null}
                                        id={props.id ? props.id + '-row-' + i : null} 
                                        className={row.selected ? [classes.highlight, classes.hover].join(' ') : classes.hover}>
                                        {
                                            cols.map(
                                                (col, j) => {
                                                    return (
                                                        j === 0 ?
                                                            <TableCell component="th" scope="row" className={conditionalFormatting(row) ? [conditionalClasses[conditionalFormatting(row)], classes.sticky].join(' ') : classes.sticky} key={props.id + "-" + col.id + "-" + j} id={props.id + "-sticky-col-" + i} 
                                                            style={row.selected ? {backgroundColor: '#515151'} : null} align={col.numeric ? 'right' : 'left'}>
                                                                {row[col.id]}
                                                            </TableCell>
                                                            :
                                                            <TableCell key={props.id + "-" + col.id + "-" + j} align={col.numeric ? 'right' : 'left'} className={conditionalFormatting(row) ? conditionalClasses[conditionalFormatting(row)] : null}>
                                                                {row[col.id]}
                                                            </TableCell>
                                                    );
                                                })
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {runners.length > 5 ? <TablePagination
                rowsPerPageOptions={[5, 10, 20, 40]}
                component="div"
                count={runners.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> : null}
        </Paper>
    );
}

export default RaceDetailsTable;
