import * as actionTypes from '../raceDetailsActions';

const initialState = {
    runners: [],
    form: [],
    filteredForm: [],
    filteredByHid: [],
    race: {},
    showFiltersModal: false,
    showFormFiltersModal: false,
    name: '',
    id: 0
};

const applyCheckboxFilter = (races, filter) => {
    let uncheckedValues = filter.checkboxes.filter( f => !f.checked );
    uncheckedValues = uncheckedValues.map( f => f.value );

    if (uncheckedValues.length === 0) return races;

    const result = races.filter(
        r => !uncheckedValues.includes(r[filter.prop])
    );

    return result;
};

const applyRangeFilter = (races, filter) => {
    let result = races;

    if (filter.gteVal && filter.gteVal > 0) {
        result = races.filter(f => f[filter.prop] >= filter.gteVal)
    }

    if (filter.lteVal && filter.lteVal > 0) {
        result = races.filter(f => f[filter.prop] <= filter.lteVal)
    }

    return result;
};

const applyRadioFilter = (races, filter) => {
    return races.filter(r => r[filter.prop] === filter.selected);
};

const winsRadioFilter = (races, filter) => {
    const selected = parseInt(filter.selected);
    if (isNaN(selected)) return races;
    else return races.filter(r => r['winplace'] >= selected);
};

const excludeBeforeFilter = (races, filter) => {
    if (filter.active) {
        return races.filter(r => r['date'] > filter.date.toISOString());
    }
    else return races;
};

const dateCheckboxesFilter = (races, filter) => {
    let uncheckedValues = filter.checkboxes.filter( f => !f.checked );
    uncheckedValues = uncheckedValues.map( f => f.value );

    if (uncheckedValues.length === 0) return races;

    const result = races.filter(
        r => !uncheckedValues.includes( new Date(r['date']).getMonth() )
    );

    return result;
};

const filterForm = (form, filters) => {
    let filtered = form;

    const keys  = Object.keys(filters);

    keys.forEach( key => {
        switch (filters[key].type) {
            case 'checkboxes':
                filtered = applyCheckboxFilter(filtered, filters[key]);
                break;
            case 'range':
                filtered = applyRangeFilter(filtered, filters[key]);
                break;
            case 'radio':
                filtered = applyRadioFilter(filtered, filters[key]);
                break;
            case 'wins':
                filtered = winsRadioFilter(filtered, filters[key]);
                break;
            case 'date':
                filtered = excludeBeforeFilter(filtered, filters[key]);
                break;
            case 'date-checkboxes':
                filtered = dateCheckboxesFilter(filtered, filters[key]);
                break;
            default:
                break;
        }

    })

    return filtered;
};

const filterByHid = (form, id) => {
    if (id > 0) {
        const filtered = form.filter(h => h.hid === id);
        return filtered;
    }
    else return form;
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SET_RACE:

        //console.log(action.form);

            return {
                ...state,
                race: action.race,
                runners: action.runners,
                form: action.form,
                filteredForm: action.form,
                filteredByHid: action.form
            };

        case actionTypes.TOGGLE_FILTERS_MODAL:
            return {
                ...state,
                showFiltersModal: !state.showFiltersModal
            };

        case actionTypes.APPLY_FILTERS_CLICKED:

            const filtered = filterForm(state.form, action.payload);
            const id = state.id;

            let filteredByHid = filtered;
            if (id > 0) filteredByHid = filterByHid(filtered, id)

            return{
                ...state,
                filteredForm: filtered,
                filteredByHid
            }
        
        case actionTypes.HORSE_ID_SELECTED: {
            const id = action.id;

            const filtered = filterByHid(state.filteredForm, id);
            
            let name = '';
            if (id > 0) name = state.runners.find(h => h.hid === id).name;

            return {
                ...state,
                filteredByHid: filtered,
                name,
                id: id
            }
        }

        case actionTypes.RESET_FORM_TOOLBAR:
            return {
                ...state,
                filteredByHid: [],
                name: '',
                id: 0
            }

        default:
            return state;
    }
};

export default reducer;