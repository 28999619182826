import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme =>
    ({
        error: {
            color: theme.palette.error.main,
            width: '100%',
        }
    })
);

const ErrorList = props => {
    const classes = useStyles();
    return (
        props.errors.length === 0 ? null :
            <ul className={classes.error}>
                {props.errors.map( (err, i) => <li key={i}><Typography color="error">{err}</Typography></li> )}
            </ul>
    );
}

export default ErrorList;