import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/raceDetailsActions';
import * as formFiltersActionTypes from '../../store/formFiltersActions';

import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Container, Button, IconButton, Checkbox, Typography, FormControlLabel, FormControl } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import NumberRangeInputGridItem from '../UI/NumberRangeInputGridItem';

import { withStyles } from '@material-ui/core/styles';

import CheckBoxFormControl from '../UI/CheckBoxFormControl';
import CourseCheckboxes from '../UI/CourseCheckboxes';

import { FiltersConsts } from '../../constants'; 
import RadioButtonsGroup from '../UI/RadioButtonsGroup';

import DatePicker from '../UI/DatePicker';

const useStyles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    selected: {
        border: '1px solid ' + theme.palette.secondary.main
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    }
});

const RaceFiltersTab = props => {
    return (
        <Fragment>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.distanceFilterChanged}
                    filterProps={props.distanceFilters}
                    title="Distance - yards" />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.prizeFilterChanged}
                    filterProps={props.prizeFilters}
                    title="Prize money" />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.noOfRunnersFilterChanged}
                    filterProps={props.noOfRunnersFilters}
                    title="Number of runners" />
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }}></Grid>
            <Grid container justify="space-around">
                <Grid item >
                    <CheckBoxFormControl title="Class" filterValues={props.classFilters} onChecked={props.classFilterChecked} />
                </Grid>
                <Grid item >
                    <CheckBoxFormControl title="Race Type" filterValues={props.raceTypeFilters} onChecked={props.raceTypeFilterChecked} />
                </Grid>
                <Grid item >
                    <CheckBoxFormControl title="Going" filterValues={props.goingFilters} onChecked={props.goingFilterChecked} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

const RaceFilters2Tab = props => {
    return (
        <Fragment>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.lowerAgeFilterChanged}
                    filterProps={props.lowerAgeFilters}
                    title="Lower age restriction" />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.upperAgeFilterChanged}
                    filterProps={props.upperAgeFilters}
                    title="Upper age restriction" />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.handicapLimitFilterChanged}
                    filterProps={props.handicapLimitFilters}
                    title="Handicap Limit" />
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }}></Grid>
            <Grid item container direction="column">
                <CourseCheckboxes title="Courses" filterValues={props.coursesFilters} onChecked={props.coursesFilterChecked} />
            </Grid>
        </Fragment>
    );
}

const RunnersFiltersTab = props => {
    return (
        <Fragment>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.drawFilterChanged}
                    filterProps={props.drawFilters}
                    title="Draw" />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.ageFilterChanged}
                    filterProps={props.ageFilters}
                    title="Age" />
            </Grid>
            <Grid item sm={6} md={4}>
                <NumberRangeInputGridItem
                    onChange={props.weightFilterChanged}
                    filterProps={props.weightFilters}
                    title="Weight - lbs" />
            </Grid>
            <Grid container item xs={12} justify="center">
                <RadioButtonsGroup filterProps={props.winsFilters} onChange={props.winsFilterSelected}></RadioButtonsGroup>
            </Grid>

        </Fragment>
    );
}

const DateFiltersTab = props => {
    const mindate = new Date("2000-01-01");
    const maxdate = new Date();
    maxdate.setHours(0, 0, 0, 0);

    return (
        <Fragment>
            <Grid container justify="center">

                <Grid item container xs={12} md={6} justify="center" alignItems="flex-start" style={{marginTop: '2em'}}>
                    <FormControl >
                        <FormControlLabel
                            control={<Checkbox checked={props.excludeBeforeFilter.active} onChange={() => { props.excludeBeforeFilterChecked(); }}/>}
                            label={<Typography variant="overline" color={props.excludeBeforeFilter.error ? 'error' : undefined}>Exclude ALL form before</Typography>} />

                        <DatePicker variant="inline" date={props.excludeBeforeFilter.date} minDate={mindate} maxDate={maxdate} label="Exclude before" onDateChanged={(date) => props.excludeBeforeFilterPicked({ date: date, minDate: mindate, maxDate: maxdate })} 
                            resetHandler={props.resetHandler} reset={props.reset} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} container justify="center" style={{marginTop: '2em'}}>
                    <CheckBoxFormControl title="Months" filterValues={props.monthsFilters} onChecked={props.monthsFilterChecked} />
                </Grid>
            </Grid>

        </Fragment>
    );
}

const DisplayTab = (props, tabSelected) => {

    switch(tabSelected) {
        case FiltersConsts.TAB_RACE_FILTERS:
            return RaceFiltersTab(props);
        case FiltersConsts.TAB_RACE_2_FILTERS:
            return RaceFilters2Tab(props);
        case FiltersConsts.TAB_RUNNERS_FILTERS:
            return RunnersFiltersTab(props);
        case FiltersConsts.TAB_DATE_FILTERS:
            return DateFiltersTab(props);
        default:
            return null;
    }
}

/**
 * @component
 * @example 
 * <FormFiltersModel open={bool}/>
 */
class FormFiltersModal extends Component {
    state = {
        reset: false
    };

    setReset = (value) => {
        const state = { ...this.state, reset: value };
        this.setState(state);
    };

    render() {
        const { classes } = this.props;
        return <Dialog 
        classes={{paper: classes.dialogPaper}}
        open={this.props.open} 
        onClose={this.props.closeModal} 
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{ style: {backgroundColor: '#303030'}}}
        >
            <DialogTitle id="form-filters">
                Form filters
                <IconButton aria-label="close" onClick={this.props.closeModal} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: '20px' }}>
                <Container>
                    <Grid container spacing={4} alignItems='flex-start' justify='center'>

                        <Grid item sm={3}>
                            <Button disabled={this.props.error} className={ this.props.tabSelected === FiltersConsts.TAB_RACE_FILTERS ? classes.selected : null } fullWidth variant="outlined" color="secondary" onClick={() => this.props.formTabClicked(FiltersConsts.TAB_RACE_FILTERS)}>
                                Race
                             </Button>
                        </Grid >
                        <Grid item sm={3}>
                            <Button disabled={this.props.error} className={ this.props.tabSelected === FiltersConsts.TAB_RACE_2_FILTERS ? classes.selected : null } fullWidth variant="outlined" color="secondary" onClick={() => this.props.formTabClicked(FiltersConsts.TAB_RACE_2_FILTERS)}>
                                Race&nbsp;2
                             </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <Button disabled={this.props.error} className={ this.props.tabSelected === FiltersConsts.TAB_RUNNERS_FILTERS ? classes.selected : null } fullWidth variant="outlined" color="secondary" onClick={() => this.props.formTabClicked(FiltersConsts.TAB_RUNNERS_FILTERS)}>
                                Runners
                            </Button>
                        </Grid>
                        <Grid item sm={3}>
                            <Button disabled={this.props.error} className={ this.props.tabSelected === FiltersConsts.TAB_DATE_FILTERS ? classes.selected : null } fullWidth variant="outlined" color="secondary" onClick={() => this.props.formTabClicked(FiltersConsts.TAB_DATE_FILTERS)}>
                                Date
                            </Button>
                        </Grid>

                        {
                            DisplayTab({...this.props, resetHandler: () => this.setReset(false), reset: this.state.reset}, this.props.tabSelected)
                        }

                        <Grid item sm={12}></Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => { this.props.resetButtonClicked(); this.setReset(true); }}>
                    Reset
                </Button>
                <Button disabled={this.props.error} variant="contained" color="primary" onClick={() => {this.props.closeModal(); this.props.applyFiltersButtonClicked(this.props.formFiltersReducer);}}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    }
}

const mapStateToProps = state => {
    return {
        classFilters: state.formFiltersReducer.classFilters,
        raceTypeFilters: state.formFiltersReducer.raceTypeFilters,
        goingFilters: state.formFiltersReducer.goingFilters,
        distanceFilters: state.formFiltersReducer.distanceFilters,
        prizeFilters: state.formFiltersReducer.prizeFilters,
        noOfRunnersFilters: state.formFiltersReducer.noOfRunnersFilters,
        tabSelected: state.formFiltersReducer.tabSelected,
        lowerAgeFilters: state.formFiltersReducer.lowerAgeFilters,
        upperAgeFilters: state.formFiltersReducer.upperAgeFilters,
        handicapLimitFilters: state.formFiltersReducer.handicapLimitFilters,
        coursesFilters: state.formFiltersReducer.coursesFilters,
        drawFilters: state.formFiltersReducer.DRAW_CHANGED,
        ageFilters: state.formFiltersReducer.AGE_CHANGED,
        weightFilters: state.formFiltersReducer.WEIGHT_CHANGED,
        winsFilters: state.formFiltersReducer.WINS_FILTER_SELECTED,
        monthsFilters: state.formFiltersReducer.MONTHS_CHECKED,
        excludeBeforeFilter:  state.formFiltersReducer.DATE_EXCLUDE_BEFORE_PICKED,
        error:  state.formFiltersReducer.error,
        formFiltersReducer: state.formFiltersReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch({ type: actionTypes.TOGGLE_FILTERS_MODAL }),
        classFilterChecked: value => dispatch({ type: formFiltersActionTypes.CLASS_CHECKED, value }),
        raceTypeFilterChecked: value => dispatch({ type: formFiltersActionTypes.RACE_TYPE_CHECKED, value }),
        goingFilterChecked: value => dispatch({ type: formFiltersActionTypes.GOING_CHECKED, value }),
        coursesFilterChecked: value => dispatch({ type: formFiltersActionTypes.COURSE_CHECKED, value }),

        distanceFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.DISTANCE_CHANGED, value, filter }),
        prizeFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.PRIZE_CHANGED, value, filter }),
        noOfRunnersFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.NO_OF_RUNNERS_CHANGED, value, filter }),

        lowerAgeFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.LOWER_AGE_CHANGED, value, filter }),
        upperAgeFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.UPPER_AGE_CHANGED, value, filter }),
        handicapLimitFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.HANDICAP_LIMIT_CHANGED, value, filter }),

        drawFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.DRAW_CHANGED, value, filter }),
        ageFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.AGE_CHANGED, value, filter }),
        weightFilterChanged: (value, filter) => dispatch({ type: formFiltersActionTypes.WEIGHT_CHANGED, value, filter }),

        winsFilterSelected: value => dispatch({ type: formFiltersActionTypes.WINS_FILTER_SELECTED, value }),

        monthsFilterChecked: value => dispatch({ type: formFiltersActionTypes.MONTHS_CHECKED, value }),
        excludeBeforeFilterPicked: dates => dispatch({ type: formFiltersActionTypes.DATE_EXCLUDE_BEFORE_PICKED, dates }),
        excludeBeforeFilterChecked: () => dispatch({ type: formFiltersActionTypes.DATE_FILTER_CHECKED }),

        formTabClicked: (value) => dispatch({ type: formFiltersActionTypes.FORM_TAB_CLICKED, value }),
        resetButtonClicked: () => dispatch({ type: formFiltersActionTypes.RESET_BUTTON_CLICKED }),

        applyFiltersButtonClicked: (payload) => dispatch({ type: actionTypes.APPLY_FILTERS_CLICKED, payload})

    }
};

/**
 * @component
 * @prop {bool} open
 */
const FormFiltersModalWrapped = connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(FormFiltersModal));
export default FormFiltersModalWrapped;