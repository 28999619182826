import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';

import axios from '../../axios-ld-api';

import RaceDetailsTable from '../../components/Race/RaceDetailsTable';
import { Grid } from '@material-ui/core';

import RaceHeaderToolbar from '../../components/Race/RaceHeaderToolbar';
import RaceFormToolbar from '../../components/Race/RaceFormToolbar';

import * as actionTypes from '../../store/raceDetailsActions';
import * as columnsActionTypes from '../../store/columnsActions';
import FormFiltersModal from '../../components/Race/FormFiltersModal';

import TwoButtonModal from '../../components/UI/TwoButtonModal';
import TransferList from '../../components/UI/TransferList';
import Loading from '../../components/UI/Loading';

const raceDetailsMeta = {
    orderBy: 'pos',
    ordering: 'asc'
};

const raceFormMeta = {
    orderBy: 'date',
    ordering: 'desc',
    colors: [
        {
            conditional: (row) => row.winplace === 2,
            style: {color: '#80cbc4'}
        },
        {
            conditional: (row) => row.winplace === 1,
            style: {color: '#90caf9'}
        }
    ],
    classes: {
        conditional0: {color: '#90caf9'},
        conditional1: {color: '#80cbc4'},
    }
};

class RaceContainer extends Component {
    state = {
        loading: true,
        results: false,
        raceType: null
    }

    componentDidMount() {
        this.props.resetFormToolbar();
        
        const raceid = this.props.match.params.rno;
        const detailUrl = axios.get('races/detail/' + raceid);
        const raceUrl = axios.get('races/' + raceid);
        const formUrl = axios.get('races/form/' + raceid);

        const state = { ...this.state };

        Promise.all([detailUrl, raceUrl, formUrl]).then( ([runners, race, form]) => {

            form.data.forEach( c => {
                if( !isNaN(c.pos) ){
                    c.pos = parseInt(c.pos);
                }
            });

            runners.data.forEach( c => {
                if( !isNaN(c.pos) ){
                    c.pos = parseInt(c.pos);
                }
            });

            this.props.setRace(race.data, runners.data, form.data);

            state.loading = false;
            state.results = race.data.result === 1;
            state.raceType = race.data.rtid < 3 ? 'flat' : 'jumps';

            this.setState(state);
            this.props.setDetailsColumns(state.results, state.raceType);

            // use/access the results 
          }).catch(errors => {
            // react on errors.
          })
    }

    render() {
        const el = this.state.loading ?
            <Loading />
            :
            <Fragment>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <RaceDetailsTable
                            runners={this.props.runners}
                            id='detail'
                            race={this.props.race}
                            toolbar={RaceHeaderToolbar}
                            columns={this.props.raceDetailsShowColumns}
                            meta={raceDetailsMeta}
                            onClick={this.props.horseIdSelected}
                            clickable/>
                    </Grid>

                    <Grid item xs={12}>
                        <RaceDetailsTable
                            runners={this.props.form}
                            id='form'
                            race={this.props.race}
                            toolbar={RaceFormToolbar}
                            columns={this.props.raceFormShowColumns}
                            meta={raceFormMeta} />
                    </Grid>

                </Grid>
                <FormFiltersModal open={this.props.showFiltersModal}/>
                <TwoButtonModal 
                    open={this.props.showColumnsModal}
                    closeModal={this.props.closeColumnsModal}
                    originalColumns={this.props.raceDetailsOriginalColumns}
                    showColumns={this.props.raceDetailsShowColumns}
                    hideColumns={this.props.raceDetailsHideColumns}
                    mapColumnsToValues={column => column.label}
                    title='Show columns'
                    button1Text='Reset'
                    button2Text='Apply'
                    button2Clicked={this.props.detailsColumnsApply}>
                        <TransferList/>
                </TwoButtonModal>
                <TwoButtonModal 
                    open={this.props.showFormColumnsModal}
                    closeModal={this.props.closeFormColumnsModal}
                    originalColumns={this.props.raceFormOriginalColumns}
                    showColumns={this.props.raceFormShowColumns}
                    hideColumns={this.props.raceFormHideColumns}
                    mapColumnsToValues={column => column.label}
                    title='Show columns'
                    button1Text='Reset'
                    button2Text='Apply'
                    button2Clicked={this.props.formColumnsApply}>
                        <TransferList/>
                </TwoButtonModal>
            </Fragment>;
        return (
            el
        );
    }
}

const mapStateToProps = state => {
    return {
        runners: state.raceDetailsReducer.runners,
        form: state.raceDetailsReducer.filteredByHid,
        race: state.raceDetailsReducer.race,
        showFiltersModal: state.raceDetailsReducer.showFiltersModal,

        showColumnsModal: state.columnsReducer.showColumnsModal,
        raceDetailsOriginalColumns: state.columnsReducer.raceDetailsOriginalColumns,
        raceDetailsShowColumns: state.columnsReducer.raceDetailsShowColumns,
        raceDetailsHideColumns: state.columnsReducer.raceDetailsHideColumns,

        showFormColumnsModal: state.columnsReducer.showFormColumnsModal,
        raceFormOriginalColumns: state.columnsReducer.raceFormOriginalColumns,
        raceFormShowColumns: state.columnsReducer.raceFormShowColumns,
        raceFormHideColumns: state.columnsReducer.raceFormHideColumns
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setRace: (race, runners, form) => dispatch({ type: actionTypes.SET_RACE, race: race, runners: runners, form: form }),
        horseIdSelected: (id) => dispatch({ type: actionTypes.HORSE_ID_SELECTED, id }),

        closeColumnsModal: () => dispatch({ type: columnsActionTypes.DETAILS_COLUMNS_TOGGLE }),
        detailsColumnsApply: (payload) => dispatch({ type: columnsActionTypes.DETAILS_COLUMNS_APPLY, payload }),

        closeFormColumnsModal: () => dispatch({ type: columnsActionTypes.FORM_COLUMNS_TOGGLE }),
        formColumnsApply: (payload) => dispatch({ type: columnsActionTypes.FORM_COLUMNS_APPLY, payload }),

        setDetailsColumns: (results, raceType) => dispatch({ type: columnsActionTypes.SET_DETAILS_COLUMNS, results, raceType }),
        resetFormToolbar: () => dispatch({ type: actionTypes.RESET_FORM_TOOLBAR })
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RaceContainer);