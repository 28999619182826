import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    minWidth: 180,
    minHeight: 230,
    maxHeight: 500,
//    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList = props => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState( props.showColumns.map(props.mapColumnsToValues) );
  const [right, setRight] = useState( props.hideColumns.map(props.mapColumnsToValues) );
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  
  props.updatePayload({left, right});
  
  const reset = props.reset;
  useEffect(
    () => {

      props.setError(left.length === 0);

      if (reset) {
        props.setReset(false);
        setLeft(props.originalColumns.map(props.mapColumnsToValues));
        setRight([]);
        setChecked([]);
        setSelectedIndex(-1);
      }

    }, [reset, props, left.length]
  )

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);

    if (left.includes(value)) {
      let index = left.indexOf(value);

      if (index === selectedIndex) index = -1;

      setSelectedIndex(index);
    }

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    setSelectedIndex(-1);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setSelectedIndex(-1);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setSelectedIndex(-1);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    setSelectedIndex(-1);
  };

  const moveUp = () => {
    if(selectedIndex === 0) return;

    var array = left.slice();

    const selected = array[selectedIndex]
    const above = array[selectedIndex - 1];
    
    array[selectedIndex] = above;
    array[selectedIndex - 1] = selected;

    setSelectedIndex(selectedIndex - 1);

    setLeft(array);
  };


  const moveDown = () => {
    if(selectedIndex === left.count - 1) return;

    var array = left.slice();

    const selected = array[selectedIndex]
    const below = array[selectedIndex + 1];
    
    array[selectedIndex] = below;
    array[selectedIndex + 1] = selected;

    setSelectedIndex(selectedIndex + 1);

    setLeft(array);
  };

  const customList = (items, isLeft) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value, index) => {

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)} selected={isLeft && selectedIndex === index}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={5}>{customList(left, true)}</Grid>
      <Grid item xs={12} sm={2}>
        <Grid container direction="column" alignItems="center">
        <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveUp}
            disabled={selectedIndex < 1 || left.length === 0}
            aria-label="move down"
          >
            <KeyboardArrowUpIcon fontSize="small"/>
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={moveDown}
            disabled={selectedIndex === -1 || selectedIndex === (left.length - 1) || left.length === 0}
            aria-label="move down"
          >
            <KeyboardArrowDownIcon fontSize="small"/>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>{customList(right)}</Grid>
    </Grid>
  );
}

export default TransferList;