import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import * as actionTypes from '../../store/raceDetailsActions';
import * as columnsActionTypes from '../../store/columnsActions';

import Grid from '@material-ui/core/Grid';
import { Toolbar, Typography, Fab, IconButton } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';

import SettingsIcon from '@material-ui/icons/Settings';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: '#000',
        backgroundColor: theme.palette.secondary.main,
        width: '100%'
    },
    icon: {
        color: '#000'
    }
}));

const RaceHeaderToolbar = props => {
    const classes = useToolbarStyles();
    //console.log(props.race);

    return (
        <Toolbar className={classes.root}>
            <Grid container justify="space-evenly">
                {
                    props.race.result === 1 ?
                        <Fragment>
                            <Grid item xs={6}>
                                <Typography>{props.race.course}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Typography>Going: {props.race.actual}</Typography>
                            </Grid>
                        </Fragment>
                        :
                        <Grid item container xs={12} justify="center" alignItems="center">
                            <Typography variant="h6">{props.race.course}</Typography>
                        </Grid>
                }
                <Grid item xs={6} >
                    <Typography variant="h6">{props.race.time}</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Typography variant="h6">£{props.race.value.toLocaleString('en-GB')}</Typography>
                </Grid>
                <Grid item container xs={6}>
                    <Grid item xs={12} >
                        <Typography variant="h6">{props.race.distance}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography>{props.race.info}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={6} style={{ textAlign: 'right' }}>
                    <Grid item xs={12} >
                        <Typography>{props.race.runners} runners</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography>{props.race.qual}</Typography>
                    </Grid>
                </Grid>
                {
                    props.race.result === 1 ?
                        <Fragment>
                            <Grid item xs={6}>
                                <Typography>Time: {props.race.seconds}s</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Typography>SP: {props.race.totalsp}%</Typography>
                            </Grid>
                        </Fragment>
                        : null
                }
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
                    <Typography>{props.race.name}</Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <IconButton aria-label="settings" className={classes.icon} style={{ marginRight: '0.25em' }} onClick={props.openColumnsModal}><SettingsIcon /></IconButton>
                    <Fab size="small" aria-label="filter selections" color="primary" onClick={props.openModal}><FilterListIcon /></Fab>
                </Grid>
            </Grid>
        </Toolbar>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => dispatch({ type: actionTypes.TOGGLE_FILTERS_MODAL }),
        openColumnsModal: () => dispatch({ type: columnsActionTypes.DETAILS_COLUMNS_TOGGLE })
    }
};

export default connect(null, mapDispatchToProps)(RaceHeaderToolbar);