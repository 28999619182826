import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Container, InputAdornment } from '@material-ui/core';
import theme from './themes/theme';
import MainAppBar from './components/Toolbar/Toolbar';

import RaceContainer from './containers/Race/RaceContainer';
import CardsContainer from './containers/Cards/CardsContainer';
import LoginContainer from './containers/Auth/LoginContainer';
import SystemsContainer from './containers/Systems/SystemsContainer';

import Footer from './containers/Footer/Footer';
import About from './containers/Pages/About';
import Contact from './containers/Pages/Contact';
import PrivacyPolicy from './containers/Pages/PrivacyPolicy';
import CookiePolicy from './containers/Pages/CookiePolicy';

import { authCheckState } from './store/actions/auth';

const App = props => {
  useEffect(() => { props.authCheckState(); });

  const privateComponent= (component) => {
    return props.isAuthenticated ? component : props.isAuthInit ? LoginContainer : null;
  };

  return (  
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainAppBar loading={props.loading || !props.isAuthInit} />
      <br></br>
      <div style={{position: 'relative', minHeight: '70vh'}}>
      {
        <BrowserRouter>
          <Container>
            <Switch>
              <Route exact path="/" component={privateComponent(CardsContainer)} />
              <Route path="/race/:rno" component={privateComponent(RaceContainer)} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/cookie-policy" component={CookiePolicy} />
              <Redirect to="/" />
            </Switch>
          </Container>
        </BrowserRouter>
      }
      </div>

      <div style={{position: 'absolute', width: '100%'}}>
        <Footer/>
      </div>

      {/* <Container>
        <SystemsContainer />
      </Container> */}


    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.mainReducer.loading,
    loadingModal: state.mainReducer.loadingModal,

    isAuthenticated: state.authReducer.token !== null,
    isAuthInit: state.authReducer.init,
    isAuthLoading: state.authReducer.loading,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    authCheckState: () => dispatch(authCheckState())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
