import React from 'react';

import {Grid, Divider, Container, Typography, Link, List, ListItem } from '@material-ui/core';
import LogoText from '../../images/laplaces_daemon_sm.svg'

const Footer = props => {
    const footer =
        <div>
            <Divider style={{marginTop: '5vh'}}/>
            <Container >
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={12} md={3}>

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container justify='center'>
                            <Link href="/">
                                <img style={{height: '70px'}} src={LogoText} alt="Laplace's Daemon"></img>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid container justify='center'>
                            <List dense={true}>
                                <ListItem>
                                    <Typography>
                                        This Site
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Link href="/about">
                                        About
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link href="/contact">
                                        Contact
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link href="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link href="/cookie-policy">
                                        Cookie Policy
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>

                    </Grid>
                </Grid>
            </Container>
        </div>

    
    return footer;
}

export default Footer;