//import 'date-fns';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import enLocale from "date-fns/locale/en-GB";
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { darkDatePickerPalette as palette } from '../../themes/palette';

/**
 * 
 * @param {{onDateChanged: function, minDate: date, maxDate: date, variant: string}} props 
 */
const DatePicker = props => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [selectedDate, setSelectedDate] = useState(() => {
    if(props.date) return props.date;
    else return today; 
  });

  /*eslint-disable */
  useEffect(() => {
    if (props.reset) {
      setSelectedDate(today);
      props.resetHandler();
    }
  });
  /*eslint-enable */

  /*eslint-disable */
  useEffect(() => {
    props.onDateChanged(selectedDate);
  }, []);
  /*eslint-enable */

  const theme = createMuiTheme({
    palette
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.onDateChanged(date)
  };

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <KeyboardDatePicker
          orientation="portrait"
          inputVariant="outlined"
          variant={props.variant}
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker"
          label={props.label}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'select race date',
          }}
          maxDate={props.maxDate}
          minDate={props.minDate}
          minDateMessage="Date out of range"
          maxDateMessage="Date out of range"
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;