import * as actionTypes from '../authActions';

import axios from '../../axios-ld-api';

import { decode as jwtDecode } from 'jsonwebtoken';

const config = {
    headers: { 'Content-Type': 'application/json' }
};

const getJwtExpiryMilliseconds = token => {
    const jwtExp = new Date(jwtDecode(token).exp * 1000);
    return (jwtExp - new Date());
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
}

export const authSuccess = (email, token) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

    return {
        type: actionTypes.AUTH_SUCCESS,
        email,
        token
    };
}

export const logout = () => {
    axios.defaults.headers.common = {};
    localStorage.removeItem('jwt');

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (timeout) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, timeout);
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error
    };
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('jwt');
        const decodedToken = jwtDecode(token);

        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(decodedToken.exp * 1000);
            
            const now = new Date();

            if (expirationDate <= now) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(decodedToken.email, token));
                dispatch(checkAuthTimeout(expirationDate - now));
            }
        }
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());

        axios.post('/auth/login', { email, password }, config)
            .then(
                response => {
                    localStorage.setItem('jwt', response.data.Token);

                    dispatch(authSuccess(response.data.Email, response.data.Token));
                    const timeout = getJwtExpiryMilliseconds(response.data.Token);
                    dispatch(checkAuthTimeout(timeout));
                }
            )
            .catch(
                error => {  
                    dispatch(authFail(error));
                }
            );
    }
}