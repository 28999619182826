import React, { Component } from 'react';
import Table from '../../components/UI/Table';

import axios from '../../axios-ld-api';

const columns = [
    {id: 'system', label: 'System', numeric: false, sortable: true},
    {id: 'datetime', label: 'Date', numeric: false, sortable: true},
    {id: 'time', label: 'Time', numeric: false, sortable: true},
    {id: 'course', label: 'Course', numeric: false, sortable: true},
    {id: 'cardno', label: 'Card No', numeric: true, sortable: true},
    {id: 'name', label: 'Name', numeric: false, sortable: true},
    {id: 'race', label: 'Race', numeric: false, sortable: true},
];

const systemsMeta = {
    orderBy: 'pos',
    ordering: 'asc'
};

class SystemsContainer extends Component {
    state = { data: [] };
    
    componentDidMount() {
        axios.get('systems')
            .then(response => { this.setState({ ...this.state, data: response['data'] }); 
            //console.log(response) 
        })
            .catch(error => console.log(error));
    }

    render() {
        return <Table id="systems" meta={systemsMeta} data={this.state.data} columns={columns} noDataMessage="No selections from systems found" rowsPerPage={20}/>
    };
}

export default SystemsContainer;