import React, { useState } from 'react';

import { Typography, FormControl, Switch, FormHelperText } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const CheckBoxFormControl = props => {
    const [state, setState] = useState(true);

    return (
        <FormControl style={{marginBottom: '1em'}}>
            <Typography color={props.filterValues.error ? 'error' : undefined} variant="overline">{props.title} <Switch size="small" checked={state} onChange={() => { props.onChecked(!state); setState(!state); }}/></Typography>
            {props.filterValues.checkboxes.map((f, i) =>
                <FormControlLabel
                    key={f.value}
                    control={<Checkbox checked={f.checked} onChange={() => props.onChecked(i)} />}
                    label={f.label} />
            )}
            <FormHelperText error style={props.filterValues.error ? null : { visibility: 'hidden' }}>No values selected</FormHelperText>
        </FormControl>
    );
}

export default CheckBoxFormControl;