import * as actionTypes from '../formFiltersActions';
import { FiltersConsts } from '../../constants';

const classFilters = {
    prop: 'class',
    type: 'checkboxes',
    checkboxes: [
        { label: 'A', value: 'A', checked: true },
        { label: 'B', value: 'B', checked: true },
        { label: 'C', value: 'C', checked: true },
        { label: 'D', value: 'D', checked: true },
        { label: 'E', value: 'E', checked: true },
        { label: 'F', value: 'F', checked: true },
        { label: 'G', value: 'G', checked: true }
    ],
    error: false
};

const raceTypeFilters = {
    prop: 'racetype',
    type: 'checkboxes',
    checkboxes: [
        { label: 'Turf', value: 1, checked: true },
        { label: 'A/W', value: 2, checked: true },
        { label: 'Chase', value: 3, checked: true },
        { label: 'Hurdle', value: 4, checked: true },
        { label: 'NHF', value: 5, checked: true }
    ],
    error: false
};

const goingFilters = {
    prop: 'going',
    type: 'checkboxes',
    checkboxes: [
        { label: 'Heavy', value: 'Heavy', checked: true },
        { label: 'Slow', value: 'Slow', checked: true },
        { label: 'Soft', value: 'Soft', checked: true },
        { label: 'Good to Soft', value: 'Good to Soft', checked: true },
        { label: 'Standard', value: 'Standard', checked: true },
        { label: 'Good', value: 'Good', checked: true },
        { label: 'Good to Firm', value: 'Good to Firm', checked: true },
        { label: 'Fast', value: 'Fast', checked: true },
        { label: 'Firm', value: 'Firm', checked: true }
    ],
    error: false
};

const monthsFilters = {
    prop: 'months',
    type: 'date-checkboxes',
    checkboxes: [
        { label: 'January', value: 0, checked: true },
        { label: 'February', value: 1, checked: true },
        { label: 'March', value: 2, checked: true },
        { label: 'April', value: 3, checked: true },
        { label: 'May', value: 4, checked: true },
        { label: 'June', value: 5, checked: true },
        { label: 'July', value: 6, checked: true },
        { label: 'August', value: 7, checked: true },
        { label: 'September', value: 8, checked: true },
        { label: 'October', value: 9, checked: true },
        { label: 'November', value: 10, checked: true },
        { label: 'December', value: 11, checked: true },
    ],
    error: false
};

const coursesFilters = { 
    prop: 'cid',
    type: 'checkboxes',
    checkboxes: FiltersConsts.COURSES_FILTERS,
    error: false
}

const distanceFilters = {
    prop: 'yards',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 4,
    helperText: ' '
}

const prizeFilters = {
    prop: 'value',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 8,
    helperText: ' '
}

const noOfRunnersFilters = {
    prop: 'runners',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 2,
    helperText: ' '
}

const lowerAgeFilters = {
    prop: 'minage',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 1,
    helperText: ' '
}

const upperAgeFilters = {
    prop: 'maxage',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 1,
    helperText: ' '
}

const handicapLimitFilters = {
    prop: 'hcap',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 3,
    helperText: ' '
}

const drawFilters = {
    prop: 'draw',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 2,
    helperText: ' '
}

const ageFilters = {
    prop: 'age',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 2,
    helperText: ' '
}

const weightFilters = {
    prop: 'weight',
    type: 'range',
    gteVal: '',
    lteVal: '',
    gteErr: false,
    lteErr: false,
    error: false,
    maxLength: 3,
    helperText: ' '
}

const excludeBeforeFilter = {
    date: '',
    prop: 'date',
    type: 'date',
    error: false,
    active: false
}

const winsFilter = {
    title: 'Form Position',
    prop: 'winplace',
    type: 'wins',
    selected: '',
    radios: [
        {label: "ALL", value: ''},
        {label: "Wins", value: '2'},
        {label: "Wins/Places", value: '1'}
    ]
}


const tabSelected = FiltersConsts.TAB_RACE_FILTERS;

const initialState = {
    classFilters,
    raceTypeFilters,
    goingFilters,
    distanceFilters,
    prizeFilters,
    noOfRunnersFilters,

    lowerAgeFilters,
    upperAgeFilters,
    handicapLimitFilters,
    coursesFilters,

    [actionTypes.DRAW_CHANGED]: drawFilters,
    [actionTypes.AGE_CHANGED]: ageFilters,
    [actionTypes.WEIGHT_CHANGED]: weightFilters,

    [actionTypes.WINS_FILTER_SELECTED]: winsFilter,

    [actionTypes.MONTHS_CHECKED]: monthsFilters,
    [actionTypes.DATE_EXCLUDE_BEFORE_PICKED]: excludeBeforeFilter,

    tabSelected,
    error: false
};

const processRange = (filterProps, value, filter) => {
    const intVal = parseInt(value);
    const strVal = value;

    const isValEmpty = strVal === "";
    const isGteEmpty = filterProps.gteVal === "";
    const isLteEmpty = filterProps.lteVal === "";

    let intGte = parseInt(filterProps.gteVal);
    let intLte = parseInt(filterProps.lteVal);

    if (filter === FiltersConsts.GTE) {
        filterProps.gteVal = strVal;

        intGte = parseInt(strVal);

        if (!isLteEmpty && !isValEmpty && intVal > intLte) filterProps.gteErr = true;
        else filterProps.gteErr = false;
    } else if (filter === FiltersConsts.LTE) {
        filterProps.lteVal = strVal;

        intLte = parseInt(strVal);

        if (!isGteEmpty && !isValEmpty && intVal < intGte) filterProps.lteErr = true;
        else filterProps.lteErr = false;
    }

    let errMsg = "Invalid range"

    if ( strVal === "" || intVal === 0 || (intVal >= intGte && intVal <= intLte)) {
        filterProps.error = filterProps.gteErr = filterProps.lteErr = false;
    }

    if (intGte === intLte) {
        if (filter === FiltersConsts.GTE) {
            filterProps.gteErr = true;
        }
        else if (filter === FiltersConsts.LTE) {
            filterProps.lteErr = true;
        }

        errMsg = "Ranges equal"
    }

    filterProps.error = filterProps.gteErr || filterProps.lteErr;

    if (filterProps.error) {
        filterProps.helperText = errMsg;
    }
    else {
        filterProps.helperText = " "
    }
}

const processCheckboxes = (checkboxes, action) => {
    if (action.value === true) {
        return checkboxes.map(c => { return ({...c, checked: true}); } );
    } else if (action.value === false) {
        return checkboxes.map(c => { return ({...c, checked: false}); } );
    } else {
        return checkboxes.map( (c,i) => { 
            if(action.value !== i ) return c;
            else return ({...c, checked: !c.checked}); 
        } );
    }
}

const processRadioGroup = (state, actionType, value) => {
    let options = { ...state[actionType] };

    options.radios = options.radios.map(r => { return { ...r } });

    options.selected = value;

    return options;
}

const processCourseCheckboxes = (checkboxes, action) => {
    if (action.value === true) {
        return checkboxes.map(c => { return ({...c, checked: true}); } );
    } else if (action.value === false) {
        return checkboxes.map(c => { return ({...c, checked: false}); } );
    } else {
        return checkboxes.map( (c,i) => { 
            if(action.value !== c.value ) return c;
            else return ({...c, checked: !c.checked}); 
        } );
    }
}

const isError = (state) => {
    const keys = Object.keys(state);

    for (let i = 0; i < keys.length; i++) {
        const o = state[keys[i]];
        if (typeof o === 'object') if (o.error === true) return true;
    }

    return false;
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.CLASS_CHECKED: {

            let checkboxes = processCheckboxes(state.classFilters.checkboxes, action);
            let error = checkboxes.findIndex( c => c.checked === true );
            error = !(error > -1);

            const newState = {
                ...state,
                classFilters: { ...state.classFilters, checkboxes, error },
            };

            newState.error = isError(newState);

            return newState;
        }
        
        case actionTypes.RACE_TYPE_CHECKED: {

            let checkboxes = processCheckboxes(state.raceTypeFilters.checkboxes, action);
            let error = checkboxes.findIndex( c => c.checked === true );
            error = !(error > -1);

            const newState = {
                ...state,
                raceTypeFilters: { ...state.raceTypeFilters, checkboxes, error },
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.GOING_CHECKED: {

            let checkboxes = processCheckboxes(state.goingFilters.checkboxes, action);
            let error = checkboxes.findIndex( c => c.checked === true );
            error = !(error > -1);

            const newState = {
                ...state,
                goingFilters: { ...state.goingFilters, checkboxes, error },
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.COURSE_CHECKED: {

            let checkboxes = processCourseCheckboxes(state.coursesFilters.checkboxes, action);
            let error = checkboxes.findIndex( c => c.checked === true );
            error = !(error > -1);

            const newState = {
                ...state,
                coursesFilters: { ...state.coursesFilters, checkboxes, error },
            };

            newState.error = isError(newState);

            return newState;
        }
        
        case actionTypes.DISTANCE_CHANGED: {

            let filterProps = { ...state.distanceFilters };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                distanceFilters: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.PRIZE_CHANGED: {

            let filterProps = { ...state.prizeFilters };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                prizeFilters: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.LOWER_AGE_CHANGED: {

            let filterProps = { ...state.lowerAgeFilters };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                lowerAgeFilters: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.UPPER_AGE_CHANGED: {

            let filterProps = { ...state.upperAgeFilters };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                upperAgeFilters: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.HANDICAP_LIMIT_CHANGED: {

            let filterProps = { ...state.handicapLimitFilters };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                handicapLimitFilters: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        
        case actionTypes.NO_OF_RUNNERS_CHANGED: {

            let filterProps = { ...state.noOfRunnersFilters };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                noOfRunnersFilters: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.DRAW_CHANGED:
        case actionTypes.AGE_CHANGED:
        case actionTypes.WEIGHT_CHANGED: {
            let filterProps = { ...state[action.type] };

            processRange(filterProps, action.value, action.filter);

            const newState = {
                ...state,
                [action.type]: filterProps,
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.WINS_FILTER_SELECTED:
            let filterProps = processRadioGroup(state, action.type, action.value);

            const newState = {
                ...state,
                [action.type]: filterProps,
            }

            newState.error = isError(newState);

            return newState;

        case actionTypes.MONTHS_CHECKED: {

            let checkboxes = processCheckboxes(state[action.type].checkboxes, action);
            let error = checkboxes.findIndex(c => c.checked === true);
            error = !(error > -1);

            const newState = {
                ...state,
                [action.type]: { ...state[action.type], checkboxes, error },
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.DATE_EXCLUDE_BEFORE_PICKED: {
            let { date, minDate, maxDate } = action.dates;
            let error = false;

            if (isNaN(date)) error = true;
            else if (date >= minDate && date <= maxDate) error = false;
            else error = true;

            if (error) date = '';

            const newState = {
                ...state,
                [action.type]: {...state[action.type], date, error }
            };

            newState.error = isError(newState);

            return newState;
        }

        case actionTypes.DATE_FILTER_CHECKED:

            return {
                ...state,
                [actionTypes.DATE_EXCLUDE_BEFORE_PICKED]:
                {
                    ...state[actionTypes.DATE_EXCLUDE_BEFORE_PICKED],
                    active: !state[actionTypes.DATE_EXCLUDE_BEFORE_PICKED].active
                }
            }

        case actionTypes.RESET_BUTTON_CLICKED: {
            const tabSelected = state.tabSelected;

            return {
                ...initialState,
                tabSelected,
                reset: true
            };
        }

        case actionTypes.FORM_TAB_CLICKED:

            return {
                ...state,
                tabSelected: action.value
            };

        default:
            return state;
    }
};

export default reducer;