// Greater/Less Than or EQual to - used for filters

export class FiltersConsts {
    static get GTE() { return 0; }
    static get LTE() { return 1; }

    static get TAB_RACE_FILTERS() { return 0; }
    static get TAB_RACE_2_FILTERS() { return 1; }
    static get TAB_RUNNERS_FILTERS() { return 2; }
    static get TAB_DATE_FILTERS() { return 3; }

    static get COURSES_FILTERS() {
        return [
            {
                "label": "Aintree",
                "value": 1,
                "checked": true
            },
            {
                "label": "Ascot",
                "value": 2,
                "checked": true
            },
            {
                "label": "Ayr",
                "value": 3,
                "checked": true
            },
            {
                "label": "Bangor-On-Dee",
                "value": 4,
                "checked": true
            },
            {
                "label": "Bath",
                "value": 5,
                "checked": true
            },
            {
                "label": "Beverley",
                "value": 6,
                "checked": true
            },
            {
                "label": "Brighton",
                "value": 7,
                "checked": true
            },
            {
                "label": "Carlisle",
                "value": 8,
                "checked": true
            },
            {
                "label": "Cartmel",
                "value": 9,
                "checked": true
            },
            {
                "label": "Catterick",
                "value": 10,
                "checked": true
            },
            {
                "label": "Cheltenham",
                "value": 11,
                "checked": true
            },
            {
                "label": "Chepstow",
                "value": 12,
                "checked": true
            },
            {
                "label": "Chester",
                "value": 13,
                "checked": true
            },
            {
                "label": "Doncaster",
                "value": 14,
                "checked": true
            },
            {
                "label": "Epsom",
                "value": 15,
                "checked": true
            },
            {
                "label": "Exeter",
                "value": 16,
                "checked": true
            },
            {
                "label": "Fakenham",
                "value": 17,
                "checked": true
            },
            {
                "label": "Folkestone",
                "value": 18,
                "checked": true
            },
            {
                "label": "Fontwell",
                "value": 19,
                "checked": true
            },
            {
                "label": "Goodwood",
                "value": 20,
                "checked": true
            },
            {
                "label": "Hamilton",
                "value": 21,
                "checked": true
            },
            {
                "label": "Haydock",
                "value": 22,
                "checked": true
            },
            {
                "label": "Hereford",
                "value": 23,
                "checked": true
            },
            {
                "label": "Hexham",
                "value": 24,
                "checked": true
            },
            {
                "label": "Huntingdon",
                "value": 25,
                "checked": true
            },
            {
                "label": "Kelso",
                "value": 26,
                "checked": true
            },
            {
                "label": "Kempton",
                "value": 27,
                "checked": true
            },
            {
                "label": "Leicester",
                "value": 28,
                "checked": true
            },
            {
                "label": "Lingfield",
                "value": 29,
                "checked": true
            },
            {
                "label": "Ludlow",
                "value": 30,
                "checked": true
            },
            {
                "label": "Market Rasen",
                "value": 31,
                "checked": true
            },
            {
                "label": "Musselburgh",
                "value": 32,
                "checked": true
            },
            {
                "label": "Newbury",
                "value": 33,
                "checked": true
            },
            {
                "label": "Newcastle",
                "value": 34,
                "checked": true
            },
            {
                "label": "Newmarket",
                "value": 35,
                "checked": true
            },
            {
                "label": "Newton Abbot",
                "value": 36,
                "checked": true
            },
            {
                "label": "Nottingham",
                "value": 37,
                "checked": true
            },
            {
                "label": "Perth",
                "value": 38,
                "checked": true
            },
            {
                "label": "Plumpton",
                "value": 39,
                "checked": true
            },
            {
                "label": "Pontefract",
                "value": 40,
                "checked": true
            },
            {
                "label": "Redcar",
                "value": 41,
                "checked": true
            },
            {
                "label": "Ripon",
                "value": 42,
                "checked": true
            },
            {
                "label": "Salisbury",
                "value": 43,
                "checked": true
            },
            {
                "label": "Sandown",
                "value": 44,
                "checked": true
            },
            {
                "label": "Sedgefield",
                "value": 45,
                "checked": true
            },
            {
                "label": "Southwell",
                "value": 46,
                "checked": true
            },
            {
                "label": "Stratford",
                "value": 47,
                "checked": true
            },
            {
                "label": "Taunton",
                "value": 48,
                "checked": true
            },
            {
                "label": "Thirsk",
                "value": 49,
                "checked": true
            },
            {
                "label": "Towcester",
                "value": 50,
                "checked": true
            },
            {
                "label": "Uttoxeter",
                "value": 51,
                "checked": true
            },
            {
                "label": "Warwick",
                "value": 52,
                "checked": true
            },
            {
                "label": "Wetherby",
                "value": 53,
                "checked": true
            },
            {
                "label": "Wincanton",
                "value": 54,
                "checked": true
            },
            {
                "label": "Windsor",
                "value": 55,
                "checked": true
            },
            {
                "label": "Wolverhampton",
                "value": 56,
                "checked": true
            },
            {
                "label": "Worcester",
                "value": 57,
                "checked": true
            },
            {
                "label": "Yarmouth",
                "value": 58,
                "checked": true
            },
            {
                "label": "York",
                "value": 59,
                "checked": true
            },
            {
                "label": "Chelmsford City",
                "value": 60,
                "checked": true
            },
            {
                "label": "Ffos Las",
                "value": 62,
                "checked": true
            },
            {
                "label": "Ballinrobe",
                "value": 90,
                "checked": true
            },
            {
                "label": "Bellewstown",
                "value": 91,
                "checked": true
            },
            {
                "label": "Clonmel",
                "value": 92,
                "checked": true
            },
            {
                "label": "Cork",
                "value": 93,
                "checked": true
            },
            {
                "label": "Curragh",
                "value": 94,
                "checked": true
            },
            {
                "label": "Down Royal",
                "value": 95,
                "checked": true
            },
            {
                "label": "Downpatrick",
                "value": 96,
                "checked": true
            },
            {
                "label": "Dundalk",
                "value": 97,
                "checked": true
            },
            {
                "label": "Fairyhouse",
                "value": 98,
                "checked": true
            },
            {
                "label": "Galway",
                "value": 99,
                "checked": true
            },
            {
                "label": "Gowran Park",
                "value": 100,
                "checked": true
            },
            {
                "label": "Kilbeggan",
                "value": 101,
                "checked": true
            },
            {
                "label": "Killarney",
                "value": 102,
                "checked": true
            },
            {
                "label": "Laytown",
                "value": 103,
                "checked": true
            },
            {
                "label": "Leopardstown",
                "value": 104,
                "checked": true
            },
            {
                "label": "Limerick",
                "value": 105,
                "checked": true
            },
            {
                "label": "Listowel",
                "value": 106,
                "checked": true
            },
            {
                "label": "Naas",
                "value": 107,
                "checked": true
            },
            {
                "label": "Navan",
                "value": 108,
                "checked": true
            },
            {
                "label": "Punchestown",
                "value": 109,
                "checked": true
            },
            {
                "label": "Roscommon",
                "value": 110,
                "checked": true
            },
            {
                "label": "Sligo",
                "value": 111,
                "checked": true
            },
            {
                "label": "Thurles",
                "value": 112,
                "checked": true
            },
            {
                "label": "Tipperary",
                "value": 113,
                "checked": true
            },
            {
                "label": "Tramore",
                "value": 114,
                "checked": true
            },
            {
                "label": "Wexford",
                "value": 115,
                "checked": true
            },
            {
                "label": "Meydan (Dubai)",
                "value": 116,
                "checked": true
            }
        ]
    }
};