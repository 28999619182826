import { Grid, Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Avatar, TextField, Button, FormHelperText, IconButton, InputAdornment, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

import ErrorList from '../../components/UI/ErrorList';
import Loading from '../../components/UI/Loading';

import * as actionTypes from '../../store/authActions';
import * as actions from '../../store/actions/auth';

import Logo from '../../images/logo.svg'

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    signin: {
        marginBottom: 16
    },
    spacing: {
        margin: 10,
    },
    spacing2: {
        margin: 16,
    }
});

const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

class LoginContainer extends Component {
    state = {
        email: '',
        password: '',
        errors: [],
        showPassword: false
    };

    onEmailInput = (event) => {
        let value = event.target.value;

        let state = this.state;

        this.setState({ ...state, email: value });
    };

    onPasswordInput = (event) => {
        let value = event.target.value;

        let state = this.state;

        this.setState({ ...state, password: value });
    };

    handleClickShowPassword = () => {
        this.setState({ ...this.state, showPassword: !this.state.showPassword });
    };
    
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleKeypress = event => {
      if (event.charCode === 13) {
        this.onLoginClick();
      }
    };

    onLoginClick = (event) => {
        let errors = [];

        const email = this.state.email;
        const password = this.state.password; 

        if (!validateEmail(email)) {
            errors.push("Invalid email format specified.")
        }

        if (password.length < 6) {
            errors.push("Password must be greater than 6 characters.")
        }

        this.setState({ ...this.state, errors });

        if (errors.length == 0) {
            this.props.onAuth(email, password);
        }
    }

    render() {
        const { classes } = this.props;

        const loginForm = 
            <Fragment>
                    <img src={Logo} style={{width: '250px', height: '250px'}}alt="Laplace's Daemon logo"></img>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon fontSize="inherit" />
                    </Avatar>
                    <Typography component="h1" variant="h5" className={classes.signin}>
                        Sign in
                    </Typography>
                    <TextField 
                        id="email" 
                        value={this.state.email} 
                        onInput={this.onEmailInput}
                        onKeyPress={this.handleKeypress}
                        label="Email address"
                        variant="outlined"
                        fullWidth 
                        className={classes.spacing} 
                    />
                    <FormControl variant="outlined" fullWidth className={classes.spacing} >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            onInput={this.onPasswordInput}
                            onKeyPress={this.handleKeypress}
                            labelWidth={85}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <ErrorList errors={this.state.errors.concat(this.props.apiErrors)}/>
                    <Button onClick={this.onLoginClick} variant="contained" color="primary" disabled={this.state.signinDisable} fullWidth className={classes.spacing}>
                        Login
                    </Button>
            </Fragment>;

        return (
            <Container component="main" maxWidth="xs" className={classes.root}>
                {!this.props.loading ? loginForm : <Loading />}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        apiErrors: state.authReducer.errors,
        loading: state.authReducer.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password))
    }
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));