import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const style = {
    height: '70vh',
    overflow: 'hidden'
};  

function Spinner() {
    return (
        <Grid container alignItems="center" justify="center" style={style}>
            <CircularProgress color="secondary" style={{width:'20vh', height:'20vh', margin:'auto'}}/>
        </Grid>
    )
};

export default Spinner;