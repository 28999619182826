import React, { useState } from 'react';

import { Typography, FormControl, Switch, FormHelperText, Grid, TextField } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './CourseCheckboxes.css';

const filterCheckboxes = (text, checkbox)  => {
    if(text === "") return true;
    else return (checkbox.label.toLowerCase().startsWith(text.toLowerCase()));
};

const CourseCheckboxes = props => {
    const [state, setState] = useState(true);
    const [search, setSearch] = useState("");

    return (
        <FormControl>
            <Typography color={props.filterValues.error ? 'error' : undefined} variant="overline">{props.title} <Switch size="small" checked={state} onChange={() => { props.onChecked(!state); setState(!state); }} /></Typography>
            <TextField label="Starts with" variant="outlined" size="small" style={{ marginTop: '1em', marginBottom: '0.5em' }}
             onChange={event => setSearch(event.target.value)}
             value={search}/>
            <FormHelperText error style={props.filterValues.error ? null : { visibility: 'hidden' }}>No values selected</FormHelperText>
            
            <Grid container justify="center">
                {
                    props.filterValues.checkboxes.filter(c => filterCheckboxes(search, c)).map(f =>
                        <Grid xs={12} sm={6} md={3} item key={f.value} className="checkbox">
                            <FormControlLabel
                                control={<Checkbox checked={f.checked} onChange={() => { props.onChecked(f.value) }} />}
                                label={f.label} />
                        </Grid>
                    )
                }
            </Grid>
        </FormControl>
    );
}

export default CourseCheckboxes;