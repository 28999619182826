import React, { cloneElement, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Container, Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles( 
    theme => ({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        }
    })
);

/**
 * 
 * @param {{open: boolean, closeModal: function, fullWidth: boolean, title: string, button1Text: string, button1Clicked: function, button2Text: string, button2Clicked: function, error: boolean}} props 
 */
const TwoButtonModal = props => {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [reset, setReset] = useState(false);
    let payload = {};

    const updatePayload = (value) => {
        payload = value;
    };

    return (
        <Dialog
            open={props.open}
            //onClose={props.closeModal}
            fullWidth={props.fullWidth}
            maxWidth={'lg'}
            PaperProps={{ style: {backgroundColor: '#303030'}}}
        >
            <DialogTitle id="form-filters">
                {props.title + '\xa0\xa0\xa0\xa0'}
                <IconButton aria-label="close" onClick={props.closeModal} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: '20px' }}>
                <Container>
                    {cloneElement(props.children, {...props, updatePayload, setError, reset, setReset})}
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => { setReset(true); }}>
                    {props.button1Text}
                </Button>
                <Button disabled={error} variant="contained" color="primary" onClick={() => { props.button2Clicked(payload); props.closeModal(); }}>
                    {props.button2Text}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TwoButtonModal;