import * as actionTypes from './actions';

const initialState = {
    loading: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REQUEST_START:
            return {
                ...state,
                loading: true
            };
        
        case actionTypes.REQUEST_END:
            return {
                ...state,
                loading: false
            };
        
        default:
            return state;
    }
};

export default reducer;