import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';

import CardPanel from '../../components/Cards/CardPanel';

import axios from '../../axios-ld-api';

import DatePicker from '../../components/UI/DatePicker';

import { Grid, Typography } from '@material-ui/core';

import { isValid } from 'date-fns';


const maxdate = new Date().setDate(new Date().getDate() + 3)
const mindate = new Date("2019-01-01")

class CardsContainer extends Component {

    state = {
        didGet: false,
        races: {},
        noRaces: false
    } 

    componentDidMount = () => this.onDateChanged(new Date());

    onDateChanged = (date) => {
        if( !isValid(date) ) return;

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        if (date > maxdate) return;
        else if (date < mindate) return;

        const state = { ...this.state };
        state.didGet = true;

        const url = "races/cards/" + year + "/" + month + "/" + day;

        this.props.onApiReqStart();
        //console.log(axios.defaults.baseURL);
        axios.get(url)
            .then(
                response => {
                    state.races = response.data;
                    state.noRaces = Object.keys(state.races).length === 0;

                    this.setState(state);
                    this.props.onApiReqEnd();
                }
            )
            .catch( 
                error => {
                    state.noRaces = true;
                    this.setState(state);
                    this.props.onApiReqEnd();
                }
            );
    };

    render() {
        const races = this.state.races;
        let cards = null;

        if (this.state.didGet) {
            cards = Object.keys(races)
                .map(course => {
                    return <CardPanel course={course} races={races[course].data} key={course} label="Select date"></CardPanel>;
                });
        }

        return (
            <Fragment>
                <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ height: '100px' }}>
                            <DatePicker onDateChanged={this.onDateChanged} variant="inline" minDate={mindate} maxDate={maxdate} />
                        </div>
                    </Grid>
                    {this.state.noRaces && <Grid item xs={12}><Typography variant="h6" color="error">No races found</Typography></Grid>}
                    {!this.state.noRaces && <Grid item xs={12}>{cards}</Grid>}
                </Grid>
            </Fragment>
        );
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onApiReqStart : () => dispatch({type: actionTypes.REQUEST_START}),
        onApiReqEnd : () => dispatch({type: actionTypes.REQUEST_END})
    }
};

export default connect(null, mapDispatchToProps)(CardsContainer);