import React, { Fragment } from 'react';
import { ExpansionPanelDetails, ExpansionPanelSummary, ExpansionPanel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardsRacesList from './CardRacesList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    accordian: {
      minWidth: '50vw'
    }
  }));

/**
 * 
 * @param {{course: string, jumps: string, races: Object, id: string, ariaControls: string}} props 
 */
const CardPanel = (props) => {
    const classes = useStyles();
    return (
        <Fragment>
            <ExpansionPanel className={classes.accordian}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={props.ariaControls}
                    id={props.id}
                >
                    <Typography variant="h6" >{props.course.toUpperCase()}</Typography>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <CardsRacesList races={props.races} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Fragment>
    );
};

export default CardPanel;