import * as actionTypes from '../columnsActions';

const raceDetailsColumnsRan = [
    {id: 'name', label: 'Name', numeric: false, sortable: true}, 
    {id: 'card', label: '#', numeric: true, sortable: true},
    {id: 'drawn', label: 'Draw', numeric: true, sortable: true},
    {id: 'draw_iv', label: 'Draw IV', numeric: true, sortable: true},
    {id: 'pos', label: 'Pos.', numeric: true, sortable: true},
    {id: 'dtw', label: 'DTW', numeric: true, sortable: true},
    {id: 'dtn', label: 'DTN', numeric: true, sortable: true},
    {id: 'or', label: 'OR', numeric: true, sortable: true},
    {id: 'history', label: 'History', numeric: false, sortable: false},
    {id: 'form', label: 'Form', numeric: false, sortable: false},
    {id: 'paceform', label: 'Pace Form', numeric: false, sortable: false},
    {id: 'pace_rating', label: 'Pace #', numeric: true, sortable: true},
    {id: 'pace', label: 'Pace', numeric: false, sortable: true},
    {id: 'pounds', label: 'Weight', numeric: true, sortable: true},
    {id: 'age', label: 'Age', numeric: true, sortable: true},
    {id: 'sex', label: 'Sex', numeric: true, sortable: true},
    {id: 'jockey', label: 'Jockey', numeric: false, sortable: false},
    {id: 'trainer', label: 'Trainer', numeric: false, sortable: false},
    {id: 'sire', label: 'Sire', numeric: false, sortable: false},
    {id: 'comments', label: 'Comments', numeric: false, sortable: false},
    {id: 'owner', label: 'Owner', numeric: false, sortable: false},
    {id: 'dslr', label: "DSLR", numeric: true, sortable: true},
    {id: 'sp_decimal', label: "SP", numeric: true, sortable: true},
    {id: 'bsp', label: "BSP", numeric: true, sortable: true},
    {id: 'irl', label: "IRL", numeric: true, sortable: true},
    {id: 'irh', label: "IRH", numeric: true, sortable: true}
];

const raceDetailsColumns = [
    {id: 'name', label: 'Name', numeric: false, sortable: true}, 
    {id: 'card', label: '#', numeric: true, sortable: true},
    {id: 'drawn', label: 'Draw', numeric: true, sortable: true},
    {id: 'draw_iv', label: 'Draw IV', numeric: true, sortable: true},
    {id: 'or', label: 'OR', numeric: true, sortable: true},
    {id: 'history', label: 'History', numeric: false, sortable: false},
    {id: 'form', label: 'Form', numeric: false, sortable: false},
    {id: 'paceform', label: 'Pace Form', numeric: false, sortable: false},
    {id: 'pace_rating', label: 'Pace #', numeric: true, sortable: true},
    {id: 'pounds', label: 'Weight', numeric: true, sortable: true},
    {id: 'age', label: 'Age', numeric: true, sortable: true},
    {id: 'sex', label: 'Sex', numeric: true, sortable: true},
    {id: 'jockey', label: 'Jockey', numeric: false, sortable: false},
    {id: 'trainer', label: 'Trainer', numeric: false, sortable: false},
    {id: 'sire', label: 'Sire', numeric: false, sortable: false},
    {id: 'owner', label: 'Owner', numeric: false, sortable: false},
    {id: 'dslr', label: "DSLR", numeric: true, sortable: true}
];

const raceDetailsColumnsJumps = raceDetailsColumns.filter( c => c.id !== 'drawn' && c.id !== 'draw_iv' );
const raceDetailsColumnsJumpsRan = raceDetailsColumnsRan.filter( c => c.id !== 'drawn' && c.id !== 'draw_iv' );

const raceFormColumns = [
    {id: 'name', label: 'Name', numeric: false, sortable: true},
    {id: 'date', label: 'Date', numeric: false, sortable: true},
    {id: 'pos', label: 'Pos.', numeric: true, sortable: true},
    {id: 'dtw', label: 'DTW', numeric: true, sortable: true},
    {id: 'dtn', label: 'DTN', numeric: true, sortable: true},
    {id: 'dslr', label: 'DSLR', numeric: true, sortable: true},
    {id: 'draw', label: 'Draw', numeric: true, sortable: true},
    {id: 'or', label: 'OR', numeric: true, sortable: true},
    {id: 'pounds', label: 'Weight', numeric: true, sortable: true},
    {id: 'pace', label: 'Pace', numeric: false, sortable: false},
    {id: 'age', label: 'Age', numeric: true, sortable: true},
    {id: 'sex', label: 'Sex', numeric: true, sortable: true},
    {id: 'jockey', label: 'Jockey', numeric: false, sortable: false},
    {id: 'trainer', label: 'Trainer', numeric: false, sortable: false},
    {id: 'comments', label: 'Comments', numeric: false, sortable: false},
    {id: 'classifications', label: 'Classifications', numeric: false, sortable: false},
    {id: 'course', label: 'Course', numeric: false, sortable: true},
    {id: 'yards', label: 'Yards', numeric: true, sortable: true},
    {id: 'distance', label: 'Dist.', numeric: false, sortable: false},
    {id: 'going', label: 'Going', numeric: false, sortable: true},
    {id: 'qual', label: 'Qualifiers', numeric: false, sortable: false},
    {id: 'value', label: 'Value', numeric: true, sortable: true},
    {id: 'hcap', label: 'Handicap', numeric: true, sortable: true},
    {id: 'racename', label: 'Race name', numeric: false, sortable: true},
    {id: 'runners', label: 'Runners', numeric: true, sortable: true}
];

const initialState = {
    showColumnsModal: false,

    previousResults: null,
    previousRaceType: null,

    raceDetailsOriginalColumns: raceDetailsColumns,
    raceDetailsShowColumns: raceDetailsColumns,
    raceDetailsHideColumns: [],

    rdOriginalColumns: raceDetailsColumns,
    rdShowColumns: raceDetailsColumns,
    rdHideColumns: [],

    rdOriginalColumnsRan: raceDetailsColumnsRan,
    rdShowColumnsRan: raceDetailsColumnsRan,
    rdHideColumnsRan: [],

    rdJumpsOriginalColumns: raceDetailsColumnsJumps,
    rdJumpsShowColumns: raceDetailsColumnsJumps,
    rdJumpsHideColumns: [],

    rdJumpsOriginalColumnsRan: raceDetailsColumnsJumpsRan,
    rdJumpsShowColumnsRan: raceDetailsColumnsJumpsRan,
    rdJumpsHideColumnsRan: [],

    showFormColumnsModal: false,
    raceFormOriginalColumns: raceFormColumns,
    raceFormShowColumns: raceFormColumns,
    raceFormHideColumns: []
};

const showHideColumns = (show, hide, columns, property) => {
    const showColumns = [];
    const hideColumns = [];

    show.forEach(value => {
        var object = columns.find(e => e[property] === value);
        showColumns.push(object);
    });

    hide.forEach(value => {
        var object = columns.find(e => e[property] === value);
        hideColumns.push(object);
    });

    return {showColumns, hideColumns};
};

const saveSettings = (results, raceType, state) => {
    if (raceType === 'flat' && results) {
        return {
            ...state,
            rdOriginalColumnsRan: state.raceDetailsOriginalColumns,
            rdShowColumnsRan: state.raceDetailsShowColumns,
            rdHideColumnsRan: state.raceDetailsHideColumns
        }
    }

    if (raceType === 'flat' && !results) {
        return {
            ...state,
            rdOriginalColumns: state.raceDetailsOriginalColumns,
            rdShowColumns: state.raceDetailsShowColumns,
            rdHideColumns: state.raceDetailsHideColumns
        }
    }

    if (raceType === 'jumps' && results) {
        return {
            ...state,
            rdJumpsOriginalColumnsRan: state.raceDetailsOriginalColumns,
            rdJumpsShowColumnsRan: state.raceDetailsShowColumns,
            rdJumpsHideColumnsRan: state.raceDetailsHideColumns
        }
    }

    if (raceType === 'jumps' && !results) {
        return {
            ...state,
            rdJumpsOriginalColumns: state.raceDetailsOriginalColumns,
            rdJumpsShowColumns: state.raceDetailsShowColumns,
            rdJumpsHideColumns: state.raceDetailsHideColumns
        }
    }

    return { ...state };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DETAILS_COLUMNS:

        const s = saveSettings(state.previousResults, state.previousRaceType, state);

            if(action.raceType === 'flat' && action.results ){
                return {
                    ...s,
                    raceDetailsOriginalColumns: s.rdOriginalColumnsRan,
                    raceDetailsShowColumns: s.rdShowColumnsRan,
                    raceDetailsHideColumns: s.rdHideColumnsRan,
                    previousResults: action.results,
                    previousRaceType: action.raceType
                }
            }

            if (action.raceType === 'flat' && !action.results) {
                return {
                    ...s,
                    raceDetailsOriginalColumns: s.rdOriginalColumns,
                    raceDetailsShowColumns: s.rdShowColumns,
                    raceDetailsHideColumns: s.rdHideColumns,
                    previousResults: action.results,
                    previousRaceType: action.raceType
                }
            }

            if (action.raceType === 'jumps' && action.results) {
                return {
                    ...s,
                    raceDetailsOriginalColumns: s.rdJumpsOriginalColumnsRan,
                    raceDetailsShowColumns: s.rdJumpsShowColumnsRan,
                    raceDetailsHideColumns: s.rdJumpsHideColumnsRan,
                    previousResults: action.results,
                    previousRaceType: action.raceType
                }

            }

            if (action.raceType === 'jumps' && !action.results) {
                return {
                    ...state,
                    raceDetailsOriginalColumns: s.rdJumpsOriginalColumns,
                    raceDetailsShowColumns: s.rdJumpsShowColumns,
                    raceDetailsHideColumns: s.rdJumpsHideColumns,
                    previousResults: action.results,
                    previousRaceType: action.raceType
                }
            }

           return state;

        case actionTypes.DETAILS_COLUMNS_TOGGLE:
            return {
                ...state,
                showColumnsModal: !state.showColumnsModal
            };

        case actionTypes.FORM_COLUMNS_TOGGLE:
            return {
                ...state,
                showFormColumnsModal: !state.showFormColumnsModal
            };

        case actionTypes.DETAILS_COLUMNS_APPLY: {
            const { showColumns, hideColumns } = showHideColumns(action.payload.left, action.payload.right, raceDetailsColumns, 'label');

            return {
                ...state,
                raceDetailsShowColumns: showColumns,
                raceDetailsHideColumns: hideColumns
            };
        }

        case actionTypes.FORM_COLUMNS_APPLY: {
            const { showColumns, hideColumns } = showHideColumns(action.payload.left, action.payload.right, raceFormColumns, 'label');

            return {
                ...state,
                raceFormShowColumns: showColumns,
                raceFormHideColumns: hideColumns
            };
        }

        default:
            return state;
    }
};

export default reducer;