import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import palette from './palette';

const theme = createMuiTheme({
    palette,
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 16,
    },
});

export default responsiveFontSizes(theme);