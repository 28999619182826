import * as actionTypes from '../authActions';

const initialState = {
    token: null,
    email: null,
    errors: [],
    loading: false,
    init: false
};

const authStart = (state, action) => {
    return {...state, errors: [], loading: true};
};

const authSuccess = (state, action) => {
    let email = action.email;
    let token = action.token;

    return { token, email, errors: [], loading: false, init: true };
};

const authFail = (state, action) => {
    let errors = action.error.response === undefined ?  ["Network error"] : ["Unauthorized"];
    return { token: null, email: null, errors, loading: false, init: true };
};

const authLogout = (state, action) => {
    return { ...state, email: null, token: null, init: true };
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);

        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);

        case actionTypes.AUTH_FAIL:
            return authFail(state, action);

        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);

        default:
            return state;
    }

};

export default reducer;