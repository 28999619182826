import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';

const RadioButtonsGroup = props => {

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend"><Typography variant="overline">{props.filterProps.title}</Typography></FormLabel>
            <RadioGroup value={props.filterProps.selected} onChange={event => props.onChange(event.target.value)}>
                {props.filterProps.radios.map(c =>
                    <FormControlLabel value={c.value} control={<Radio />} label={c.label} key={c.value} />
                )}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioButtonsGroup;