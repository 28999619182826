import React, { Fragment } from 'react';

import { List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    sectionHeader: {
        fontSize: '1em'
    }
}));

const About = () => {

    const classes = useStyles();

    return (
        <Fragment>
            <Typography color="textSecondary" variant="h5">What's the purpose of this site?</Typography>
            <br></br>
            <Typography>This is a private website. It is not meant for use by the general public.</Typography>
        </Fragment>
    );
};

export default About;