import React, { Fragment } from 'react';

import { Grid, Typography, TextField, FormHelperText } from '@material-ui/core';

import { FiltersConsts } from '../../constants';

const rectifyInput = (event, maxLength) => {
  let value = event.target.value;

  if (value.length > maxLength) {
    value = value.slice(0, maxLength);
  }

  value = value.replace(/^[0]+/, '');
  value = value.replace(/^[-]/, '');
  value = value.replace(/[.]/, '');

  event.target.value = value;
}

const NumberRangeInputGridItem = props => {

  return (
    <Fragment>
      <Typography variant="overline" color={props.filterProps.error ? 'error' : undefined}>{props.title}</Typography>
      <Grid container alignItems='center'>
        <Grid item xs={5}>
          <TextField type="number" label="&ge;" variant="outlined" size="small" color={props.filterProps.gteErr ? null : 'secondary'}
            onInput={event => rectifyInput(event, props.filterProps.maxLength)}
            onChange={event => props.onChange(event.target.value, FiltersConsts.GTE)}
            value={props.filterProps.gteVal}
            error={props.filterProps.gteErr}/>
        </Grid>
        <Grid item xs={2}>
          <Typography align='center' variant="overline" display="block">TO</Typography >
        </Grid>
        <Grid item xs={5} style={{ textAlign: 'right' }}>
          {/* <FormControl size="small">
                    <InputLabel variant="outlined" error={props.filterProps.lteErr}>&le;</InputLabel>
                    <OutlinedInput
                    label="&le;"
                    type="number"
                    color={props.filterProps.lteErr ? null : 'secondary'}
                    value={props.filterProps.lteVal}
                    onInput={event => RectifyInput(event, props.filterProps.maxLength)}
                    onChange={event => props.onChange(event.target.value, FiltersConsts.LTE)} 
                    error={props.filterProps.lteErr}
                    />
                    {props.filterProps.lteErr ? <FormHelperText style={{position: 'relative'}} error={props.filterProps.lteErr}>yo</FormHelperText> : null}
                  </FormControl> */}
          <TextField type="number" label="&le;" variant="outlined" size="small" color={props.filterProps.lteErr ? null : 'secondary'}
            onInput={event => rectifyInput(event, props.filterProps.maxLength)}
            onChange={event => props.onChange(event.target.value, FiltersConsts.LTE)}
            value={props.filterProps.lteVal}
            error={props.filterProps.lteErr}/>
        </Grid>
        <Grid item container xs={12} justify="space-evenly"><FormHelperText error variant="outlined">{props.filterProps.helperText}</FormHelperText></Grid>
      </Grid>
    </Fragment>
  );

}

export default NumberRangeInputGridItem;