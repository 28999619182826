import React, { Fragment } from 'react';

import { List, ListItem, Typography, Box, Link } from '@material-ui/core';


const CookiePolicy = () => {
    return (
        <Fragment>
            <Typography color="textSecondary" variant="h5">This site does not use cookies</Typography>
            <br></br>
            <Typography>This site does not use cookies, as explained in the <Link color="primary" href="/privacy-policy">privacy policy</Link>, you
                are not tracked or marketed to. Information is persisted by using your browser's <Box style={{display: 'inline'}} fontFamily="Monospace">localStorage</Box> . 
                The information persisted is your login and the settings you have selected.</Typography>
            <br></br>
            <Typography color="textSecondary" variant="h5">Isn't <Box style={{display: 'inline'}} fontFamily="Monospace">localStorage</Box>  insecure?</Typography>
            <br></br>
            <Typography>There is a misconception that <Box style={{display: 'inline'}} fontFamily="Monospace">localStorage</Box> is vulnerable to XSS (cross site scripting) attacks whereas cookies are not.
                Any XSS attack that can steal your data from <Box style={{display: 'inline'}} fontFamily="Monospace">localStorage</Box> can also 
                steal your <Box style={{display: 'inline'}} fontFamily="Monospace">HttpOnly</Box> cookies.
            </Typography>
        </Fragment>
    );
};

export default CookiePolicy;