import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  time: {
      color: theme.palette.text.secondary
  }
}));


const CardRacesList = (props) => {
  const classes = useStyles();

  let races = props.races.map( (race, i) => {
      return (
          <Fragment key={race.id}>
              <ListItem button key={race.id}  onClick={() => props.history.push('/race/' + race.id )}>
                  <Grid container key={race.id} spacing={2} alignItems="baseline" wrap="nowrap" className={classes.nowrap} justify="space-evenly">
                      <Grid item>
                          <Typography className={classes.time}>
                            <strong>{race.time}</strong>
                          </Typography>
                      </Grid>
                      <Grid container item alignItems="baseline" justify="space-between">
                          <Grid item xs={12} sm={12} md={10}>
                              <Typography>
                                  {race.name}
                              </Typography>
                          </Grid>

                          <Grid item md={"auto"}>
                            {race.runners} runners
                          </Grid>
                      </Grid>
                  </Grid>
              </ListItem>
              {i !== props.races.length - 1 ? <Divider /> : null}
          </Fragment>
      );
  });

  return (
    <div className={classes.root}>
      <List>
          {races}
      </List>
    </div>
  );
}

export default withRouter(CardRacesList);