import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontFamily: 'Cinzel'
    },
    hidden: {
        visibility: 'hidden'
    }
}));

const MainAppBar = props => {

    const classes = useStyles();

    return (
        <Box mb={2}>
            <AppBar position="static">
                <Toolbar wrap="nowrap">
                    <Typography variant="h4" className={classes.title}>
                        Laplace's Dæmon
                    </Typography>
                </Toolbar>
            </AppBar>
            <LinearProgress color="secondary" className={props.loading ? null : classes.hidden}/>
        </Box>
    );
}

export default MainAppBar;