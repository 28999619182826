import React from 'react';

import { connect } from 'react-redux';
import * as columnsActionTypes from '../../store/columnsActions';

import Grid from '@material-ui/core/Grid';
import { Toolbar, Typography, IconButton } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import SettingsIcon from '@material-ui/icons/Settings';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: '#000',
        backgroundColor: theme.palette.primary.main,
        width: '100%',
    },
    icon: {
        color: '#000'
    }
}));

const RaceFormToolbar = props => {
    const classes = useToolbarStyles();

    return(
        <Toolbar className={classes.root}>
            <Grid container justify="space-evenly">
                <Grid item container xs={12}>
                    <Grid item xs={11}>
                        <Typography variant='h6'>Form</Typography>
                    </Grid>
                    <Grid item container justify="flex-end" xs={1}>
                        <IconButton aria-label="form-columns" style={{}} className={classes.icon} onClick={props.openColumnsModal}><SettingsIcon/></IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{props.name + '\xa0'}</Typography>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const mapStateToProps = state => {
    return {
        name: state.raceDetailsReducer.name
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openColumnsModal: () => dispatch({ type: columnsActionTypes.FORM_COLUMNS_TOGGLE })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RaceFormToolbar);