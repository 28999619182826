export const CLASS_CHECKED = "CLASS_FILTER_CHECKED";
export const RACE_TYPE_CHECKED = "RACE_TYPE_CHECKED";
export const GOING_CHECKED = "GOING_CHECKED";
export const COURSE_CHECKED = "COURSE_CHECKED";

export const DISTANCE_CHANGED = "DISTANCE_CHANGED";
export const PRIZE_CHANGED = "PRIZE_CHANGED";
export const NO_OF_RUNNERS_CHANGED = "NO_OF_RUNNERS_CHANGED";
export const LOWER_AGE_CHANGED = "LOWER_AGE_CHANGED";
export const UPPER_AGE_CHANGED = "UPPER_AGE_CHANGED";
export const HANDICAP_LIMIT_CHANGED = "HANDICAP_LIMIT_CHANGED";

export const DRAW_CHANGED = "DRAW_CHANGED";
export const AGE_CHANGED = "AGE_CHANGED";
export const WEIGHT_CHANGED = "WEIGHT_CHANGED";

export const WINS_FILTER_SELECTED = "WINS_FILTER_SELECTED";

export const FORM_TAB_CLICKED = "FORM_TAB_CLICKED";
export const RESET_BUTTON_CLICKED = "RESET_BUTTON_CLICKED";

export const MONTHS_CHECKED = "MONTHS_CHECKED";
export const DATE_EXCLUDE_BEFORE_PICKED = "DATE_EXCLUDE_BEFORE_PICKED";
export const DATE_FILTER_CHECKED = "DATE_FILTER_CHECKED";

