import { colors } from '@material-ui/core';

const palette = {
    primary: { 
        main: colors.purple[200]
    },
    secondary: {
        main: colors.teal[200]
    },
    error: {
        main: '#FF9090'
    },
    type: 'dark',
    background: {
        default: '#000'
    }
};

const darkDatePickerPalette = {
    primary: {
        main: colors.purple[200]
    },
    error: {
        main: '#FF8080'
    },
    type: 'dark',
    background: {
        default: colors.purple[200]
    }
};

export default palette;
export { darkDatePickerPalette };